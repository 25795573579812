import { withSuperJSONProps as _withSuperJSONProps, withSuperJSONPage as _withSuperJSONPage } from "babel-plugin-superjson-next/tools";
import { customerAdminConverter, eventAdminConverter, initializeFirebaseNodeApp } from '@livekatsomo/node/firebase-utils';
import { firestorePaths } from '@livekatsomo/shared/config';
import { HomePage, HomePageProps } from '@livekatsomo/web/pages/home';
import { Timestamp, getFirestore } from 'firebase-admin/firestore';
import { GetStaticProps } from 'next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';

// import { useServerTimeOffset } from '@livekatsomo/web/hooks';

// This function gets called at build time on server-side.
// It may be called again, on a serverless function, if
// revalidation is enabled and a new request comes in
export const getStaticProps: GetStaticProps<HomePageProps> = _withSuperJSONProps(async ({
  locale
}) => {
  initializeFirebaseNodeApp();
  const adminDb = getFirestore();
  try {
    const [events, vodEvents, customers] = await Promise.all([getPublicEvents(adminDb), getPublicVodEvents(adminDb), getStores(adminDb)]);
    const translations = await serverSideTranslations(locale!, ['common', 'zod']);
    return {
      props: {
        ...translations,
        events,
        vodEvents,
        customers
      },
      // Next.js will attempt to re-generate the page:
      // - When a request comes in
      // - At most once every 10 seconds
      revalidate: 10 // In seconds
    };
  } catch (error) {
    console.error(error);
    return {
      props: {
        events: [],
        customers: []
      },
      // Next.js will attempt to re-generate the page:
      // - When a request comes in
      // - At most once every 10 seconds
      revalidate: 10 // In seconds
    };
  }
}, []);
export default _withSuperJSONPage(HomePage);
async function getStores(adminDb: FirebaseFirestore.Firestore) {
  return await adminDb.collection(firestorePaths.customerCollectionPath).where('customerType', '==', 'shop').where('visibility', '==', 'public').withConverter(customerAdminConverter).get().then(snapshot => snapshot.docs.map(doc => doc.data()));
}
async function getPublicEvents(adminDb: FirebaseFirestore.Firestore) {
  return await adminDb.collection(firestorePaths.eventCollectionPath).where('archived', '==', false).where('visibility', '==', 'public').where('endDate', '>', Timestamp.now()).withConverter(eventAdminConverter).get().then(snapshot => snapshot.docs.map(doc => doc.data()));
}
async function getPublicVodEvents(adminDb: FirebaseFirestore.Firestore) {
  return await adminDb.collection(firestorePaths.eventCollectionPath).where('archived', '==', false).where('visibility', '==', 'public').where('vodEnabled', '==', true).where('vodExpiryDate', '>', Timestamp.now()).withConverter(eventAdminConverter).get().then(snapshot => snapshot.docs.map(doc => doc.data()));
}