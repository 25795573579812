import { CustomerDoc, EventDoc } from '@livekatsomo/models';
import { useCustomers, useEvents } from '@livekatsomo/web/data-hooks';
import AdminMenuFeature from '@livekatsomo/web/features/admin-menu';
import { ErrorBoundary } from '@livekatsomo/web/features/error-boundary';
import { HomeFeature } from '@livekatsomo/web/features/home-feature';
import UserMenuFeature from '@livekatsomo/web/features/user-menu';
import {
  CustomersProvider,
  EventsProvider,
} from '@livekatsomo/web/providers/shared';
import { FallbackSpinner } from '@livekatsomo/web/ui-components/fallback-spinner';
import { MainMenuItems } from '@livekatsomo/web/ui-components/layout';
import { whereFilter } from '@livekatsomo/web/utils';
import { where } from 'firebase/firestore';
import { NextPage } from 'next';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import { Suspense } from 'react';

const absoluteUrl = process.env.NEXT_PUBLIC_VERCEL_URL
  ? `https://${process.env.NEXT_PUBLIC_VERCEL_URL}`
  : process.env.NEXT_PUBLIC_BASE_URL;

const AuthenticationProviderCSR = dynamic(
  () => import('@livekatsomo/web/providers/authentication'),
  {
    ssr: false,
  },
);

/**
 * Props for the HomePage component.
 */
export interface HomePageProps {
  events?: EventDoc[];
  vodEvents?: EventDoc[];
  customers?: CustomerDoc[];
}

/**
 * The home page component.
 * @param events An array of events to display.
 * @param vodEvents An array of VOD events to display.
 * @param customers An array of customers to display.
 * @returns The home page component.
 */
export const HomePage: NextPage<HomePageProps> = ({
  events,
  vodEvents,
  customers,
}) => {
  return (
    // Provider getting events with visibility public and endDate > now
    <EventsProvider
      queryConstraints={[
        whereFilter<EventDoc>('archived', '==', false),
        whereFilter<EventDoc>('visibility', '==', 'public'),
        whereFilter<EventDoc>('endDate', '>', new Date()),
      ]}
      initialState={events}
    >
      <CustomersProvider
        queryConstraints={[
          where('customerType', '==', 'shop'),
          where('visibility', '==', 'public'),
        ]}
        initialState={customers}
      >
        <Suspense fallback={<FallbackSpinner />}>
          <EventsWrapper vodEvents={vodEvents} />
        </Suspense>
      </CustomersProvider>
    </EventsProvider>
  );
};

export default HomePage;

/**
 * Wrapper component for VOD events.
 * @param vodEvents An array of VOD events to display.
 * @returns The VOD events wrapper component.
 */
function EventsWrapper({ vodEvents }: { vodEvents?: EventDoc[] }) {
  const { events } = useEvents();

  return (
    <EventsProvider
      queryConstraints={[
        whereFilter<EventDoc>('archived', '==', false),
        whereFilter<EventDoc>('visibility', '==', 'public'),
        whereFilter<EventDoc>('vodEnabled', '==', true),
        whereFilter<EventDoc>('vodExpiryDate', '>', new Date()),
      ]}
      initialState={vodEvents}
    >
      <Suspense fallback={<FallbackSpinner />}>
        <VodEventsWrapper events={events || []} />
      </Suspense>
    </EventsProvider>
  );
}

/**
 * Wrapper component for VOD events.
 * @param events An array of events to display.
 * @returns The VOD events wrapper component.
 */
function VodEventsWrapper({ events }: { events: EventDoc[] }) {
  const { events: vodEnabledEvents } = useEvents();
  const { customers } = useCustomers();

  const vodEvents = vodEnabledEvents?.filter(
    (event) => event.endDate < new Date(),
  );

  const ogImageUrl = `${absoluteUrl}/api/og`;

  return (
    <>
      <Head>
        <title>Rajucast</title>
        <meta name="description" content="Happens to be where it happens" />
        <meta property="og:title" content="Rajucast" />
        <meta
          property="og:description"
          content="Happens to be where it happens"
        />
        <meta property="og:image" content={ogImageUrl} />
      </Head>
      <HomeFeature
        events={[...events, ...(vodEvents || [])]}
        stores={customers}
        drawerMenuItems={
          <AuthenticationProviderCSR>
            <>
              <MainMenuItems />
              <ErrorBoundary>
                <Suspense>
                  <AdminMenuFeature />
                </Suspense>
              </ErrorBoundary>
            </>
          </AuthenticationProviderCSR>
        }
        userMenu={
          <AuthenticationProviderCSR>
            <Suspense>
              <UserMenuFeature />
            </Suspense>
          </AuthenticationProviderCSR>
        }
      />
    </>
  );
}
